import React, { ChangeEvent, useState } from "react";
import { FormQuestion } from "../FormQuestion/FormQuestion";

interface GenderInputProps {
    testId?: string;
    onChangeFunction: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export enum Gender {
    Female,
    Male
}

interface GenderValue {
    value: string;
}

export const GenderInput: React.FC<GenderInputProps> = ({ testId, onChangeFunction }) => {
    const [genderValue, setGenderValue] = useState<GenderValue>({ value: "" });

    return <FormQuestion testId={testId} label="Gender">
        <div className="field-container-box">
            <div className="radio-field">
                <label>
                    <input type="radio" name="gender" value={Gender.Female.toString()} checked={genderValue.value === Gender.Female.toString()} onChange={(event) => { setGenderValue({ ...genderValue, value: event.target.value }); onChangeFunction(event) }} />
                    <span>Female</span>
                </label>
            </div>
            <div className="radio-field">
                <label>
                    <input type="radio" name="gender" value={Gender.Male.toString()} checked={genderValue.value === Gender.Male.toString()} onChange={(event) => { setGenderValue({ ...genderValue, value: event.target.value }); onChangeFunction(event) }} />
                    <span>Male</span>
                </label>
            </div>
        </div>
    </FormQuestion>
}