import React, { useState } from "react";
import { FormQuestion } from "../FormQuestion/FormQuestion";
import "./BirthDateInput.scss"

interface BirthDateInputProps {
    testId?: string;
    onDateChange: (dateValue: DateValue) => void;
}

export interface DateValue {
    day: string;
    month: string;
    year: string;
}

export const BirthDateInput: React.FC<BirthDateInputProps> = ({ testId, onDateChange }) => {
    const [dateValue, setDateValue] = useState<DateValue>({
        day: '',
        month: '',
        year: ''
    })

    return <FormQuestion type="text" label="Date of birth" testId={testId}>
        <div className="field-container-box birthdate-row">
            <input
                type="number"
                value={dateValue.day}
                onChange={(field) => { setDateValue({ ...dateValue, day: field.target.value }); onDateChange(dateValue); }}
                className="birthdate-field"
                placeholder="MM"
            />
            <input
                type="number"
                value={dateValue.month}
                onChange={(field) => { setDateValue({ ...dateValue, month: field.target.value }); onDateChange(dateValue); }}
                className="birthdate-field"
                placeholder="DD"
            />
            <input
                type="number"
                value={dateValue.year}
                onChange={(field) => { setDateValue({ ...dateValue, year: field.target.value }); onDateChange(dateValue); }}
                className="birthdate-field"
                placeholder="YYYY"
            />
        </div>
    </FormQuestion>
}