import React, { ChangeEvent, useState } from "react";
import { RadioInputItem } from "./RadioInputItem/RadioInputItem";
import { FormQuestion } from "../FormQuestion/FormQuestion";

interface ScoringInputProps {
    testId?: string;
    options: string[];
    onChangeFunction: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface ScoringValue {
    value: string;
}

export const ScoringInput: React.FC<ScoringInputProps> = ({ testId, options, onChangeFunction }) => {
    const [scoringValue, setScoringValue] = useState<ScoringValue>({ value: "" });

    return <FormQuestion testId={testId} label="On a scale of 0 to 4, what is the level of anxiety you are experiencing right now?" multiline>
        {Object.entries(options).map((item, index) => (
            <RadioInputItem
                index={index}
                item={item[1]}
                onChangeFunction={(event) => { setScoringValue({ ...scoringValue, value: event.target.value }); onChangeFunction(event); }}
                name={"anxiety"}
                checked={scoringValue.value!== "" && index === +scoringValue.value}
                key={item.toString()} />
        ))}
    </FormQuestion>
}