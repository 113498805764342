import React from 'react';
import './App.scss';
import LandingPage from './screens/LandingPage/LandingPage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import QuestionnairePage from './screens/QuestionnairePage/QuestionnairePage';
import PreQualifiedThankYouPage from './screens/PreQualifiedThankYouPage/PreQualifiedThankYouPage';

function App() {
  return(
    <Router>
      <Routes>
        <Route path = "/" element = {<LandingPage />}></Route>
        <Route index element = {<LandingPage />}></Route>
        <Route path = "questionnaire" element = {<QuestionnairePage />}></Route>
        <Route path = "prequalified" element = {<PreQualifiedThankYouPage />}></Route>
        <Route path = "*" element = {<LandingPage />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
