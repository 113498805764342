import React from "react";
import "./Breadcrumb.scss";
import separator from "../../../assets/Vector.png"

interface BreadcrumbProps {
    steps: string[];
    activeStep: number;
    testId?: string;
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({ steps, activeStep, testId }) => {
    return <div className="breadcrumb" data-testid={testId}>
        {steps.map((step, index) => (
            <React.Fragment key={index}>
                <div
                    className={`step ${index === activeStep ? 'active' : ''}`}>
                    {step}
                </div>
                {index < steps.length - 1 && <span className="separator"><img src={separator} alt="separator" /></span>}
            </React.Fragment>
        ))}
    </div>;
}