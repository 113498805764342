import React from "react";
import "./PreQualifiedText.scss"

interface PreQualifiedTextProps {
    testId?: string;
}

export const PreQualifiedText: React.FC<PreQualifiedTextProps> = ({ testId }) => {
    return <div className='prequalified-text-container' data-testid={testId}>
        <div className='prequalified-text-column'>
            <div className='prequalified-title'>You are pre-qualified for our trial!</div>
            <div className='prequalified-description'>Thank you for your interest in our clinical trial. To proceed with the next steps, we kindly ask you to follow the instructions provided to you in the e-mail that we’ve sent.</div>
        </div>
    </div>
}
