import React from "react";
import "./LandingPageDescription.scss"

interface LandingPageDescriptionProps{
  testId?: string;
}

const LandingPageDescription : React.FC<LandingPageDescriptionProps> = ({testId}) => {  
  return <div className="intro-text" data-testid = {testId} >
      <p>If you are currently taking antidepressants and are still depressed then this study could be for you. Qualified participants may receive:</p>
      <ul>
        <li>Compensation up to $1,500</li>
        <li>Study-related care at no cost to you</li>
        <li>Free in-app vital measurements</li>
        <li>Health insurance is not required</li>
      </ul>
    </div>
}

export default LandingPageDescription;
