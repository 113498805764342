import React, { useState } from "react";
import { FormQuestion } from "../FormQuestion/FormQuestion";
import "./CheckBoxField.scss";

interface CheckBoxFieldProps {
    testId?: string;
    label: string;
    onFieldChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface Checked {
    value: boolean;
}

export const CheckBoxField: React.FC<CheckBoxFieldProps> = ({ testId, label, onFieldChange }) => {
    const [checked, setChecked] = useState<Checked>({ value: false });

    return <FormQuestion testId={testId}>
        <div className="checkbox-input-field">
            <div className="checkbox-col">
                <label className="checkbox-container">
                    <input type="checkbox" checked={checked.value} onChange={(event) => { setChecked({ ...checked, value: !checked.value }); onFieldChange(event) }} />
                    <span className="checkmark"></span>
                </label>
            </div>
            <div className="checkbox-text-col">
                <span>{label}</span>
            </div>
        </div>
    </FormQuestion>
}