import React from "react";
import endavaLogo from "./assets/endava_logo.png"
import binahLogo from "./assets/binah_ai_logo.png";
import "./Providers.scss";

interface ProvidersProps {
    testId?: string;
}

export const Providers: React.FC<ProvidersProps> = ({ testId }) => {
    return <div className='credits-container' data-testid={testId} >
    <div className='credits-column'>
      <div className='provided-by'>Solution provided to you by</div>
      <div className='providers-box'>
        <img src={endavaLogo} className='endava-logo' alt="endava logo" />
        <img src={binahLogo} className='binah-logo' alt="binah logo" />
      </div>
    </div>
  </div>
}
