import React from 'react';
import Background from '../../commonComponents/background/Background';
import Logo from '../../commonComponents/logo/logo';
import "./PrequalifiedThankYouPage.scss";
import { PreQualifiedImage } from './PreQualifiedImage/PreQualifiedImage';
import { PreQualifiedText } from './PreQualifiedText/PreQualifiedText';
import { Providers } from './Providers/Providers';

const PreQualifiedThankYouPage: React.FC = () => {
  return (
    <div className='static-page'>
      <Background testId='prequalifiedBackground' />
      <div className="static-page-container">
        <Logo testId="logo" />
        <PreQualifiedImage testId='prequalified-image' />
        <PreQualifiedText testId='prequalified-text' />
        <Providers testId='providers' />
      </div>
    </div>
  );
};

export default PreQualifiedThankYouPage;
