import Title from './title/Title';
import LandingPageDescription from './landingPageDescription/LandingPageDescription';
import React from 'react';
import './LandingPage.scss';
import Logo from '../../commonComponents/logo/logo';
import { Disclaimer } from './disclaimer/Disclaimer';
import Button from '../../commonComponents/button/Button';
import { useNavigate } from 'react-router';
import Background from '../../commonComponents/background/Background';

const LandingPage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className='static-page'>
      <Background testId='landingBackground' />
      <div className="static-page-container">
        <Logo testId="logo" />
        <Title testId="title" />
        <LandingPageDescription testId="description" />
        <Button buttonClicked={() => navigate("/questionnaire")} buttonText="Check your eligibility" testId="startFlowButton" className="landing-page-button" />
        <Disclaimer testId="disclaimer"/>
      </div>
    </div>
  );
};

export default LandingPage;
