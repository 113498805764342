import React from "react";
import "./Button.scss";

interface ButtonProps{
  buttonClicked: () => void;
  buttonText: string;
  testId?: string;
  className?: string;
}

const Button: React.FC<ButtonProps> = ({
  buttonClicked,
  buttonText,
  testId,
  className
}) => {
  return <button onClick={() => buttonClicked()} className={`main-button ${className?className:''}`} data-testid={testId}>{buttonText}</button>;
}

export default Button;
  