import React, { useState } from "react";
import "./QuestionnaireForm.scss"
import Button from "../../../commonComponents/button/Button";
import { TextInput } from "./TextInput/TextInput";
import { BirthDateInput, DateValue } from "./BirthDateInput/BirthDateInput";
import { GenderInput } from "./GenderInput/GenderInput";
import { CheckBoxField } from "./CheckBoxField/CheckBoxField";
import { ScoringInput } from "./ScoringInput/ScoringInput";
import { useNavigate } from "react-router-dom";

interface QuestionnaireFormProps {
    testId?: string;
}

interface FormData {
    email: string;
    weight: string;
    height: string;
    gender: string;
    anxiety: string;
    location: string;
    accessStudySite: boolean;
    anxietyMedResponse: boolean;
    birthDate: string;
}

const QuestionnaireForm: React.FC<QuestionnaireFormProps> = ({ testId }) => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState<FormData>({
         email: "",
         weight: "",
         height: "",
         gender: "",
         anxiety: "",
        location: "",
        accessStudySite: false,
        anxietyMedResponse: false,
        birthDate: ""});

        const options =  ["None","Mild", "Moderate","Severe","Very Severe"];

    return <div className="questionnaire-form-container" data-testid={testId} >
        <div className="questionnaire-form">
            <TextInput inputType="email" label="Your e-mail" value={formData.email} onChange={(event) => setFormData({ ...formData, email: event.target.value })} testId="questionnaireEmail" />
            <BirthDateInput onDateChange={(dateValue: DateValue)=> setFormData({...formData, birthDate: `${dateValue.day}-${dateValue.month}-${dateValue.year}`}) } testId="birthDate"/>
            <TextInput inputType="number" label="Weight(kg)" value={formData.weight} onChange={(event) => setFormData({ ...formData, weight: event.target.value })} testId="questionnaireWeight" />
            <TextInput inputType="number" label="Height(cm)" value={formData.height} onChange={(event) => setFormData({ ...formData, height: event.target.value })} testId="questionnaireHeight" />
            <GenderInput onChangeFunction={(event) => setFormData({...formData, gender: event.target.value})} testId="gender" />
            <TextInput inputType="text" label="Location" value={formData.location} onChange={(event) => setFormData({ ...formData, location: event.target.value })} testId="Location" />
            <CheckBoxField label="I am able to read or to access the study site" onFieldChange={(event) => setFormData({ ...formData, accessStudySite: !formData.accessStudySite })} testId="accessToSite"/>
            <ScoringInput options={options} onChangeFunction={(event) => setFormData({ ...formData, anxiety: event.target.value })} testId="anxiety" />
            <CheckBoxField label="I did not respond to prior treatment with anxiety medication" onFieldChange={(event) => setFormData({ ...formData, anxietyMedResponse: !formData.anxietyMedResponse })} testId="medication"/>
            <Button buttonClicked={() => navigate("/prequalified")} buttonText="Next" testId="questionnaireNextButton" className="questionnaire-button" />
        </div>
    </div>
}

export default QuestionnaireForm;
