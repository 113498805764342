import React from "react";
import logo from '../../assets/logo.png';
import "./logo.scss"

interface LogoProps {
  testId?: string;
}

const Logo: React.FC<LogoProps> = ({testId}) => {
  return <div className="logo-container" data-testid={testId}>
          <div className="logo">
            <img src={logo} alt="Logo" className="logo" />
          </div>
          <p>RESEARCH HUB</p>
        </div>;
}

export default Logo;
  