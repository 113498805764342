import React from "react";
import hands from "./assets/hands.png";
import "./PreQualifiedImage.scss"

interface PreQualifiedImageProps {
    testId?: string;
}

export const PreQualifiedImage: React.FC<PreQualifiedImageProps> = ({ testId }) => {
    return <div className='prequalified-image-container' data-testid = {testId}>
        <img src={hands} className='prequalified-image' alt="hands" />
    </div>;
}
