import React from "react";
import "./Background.scss"

interface BackgroundProps{
  testId?: string;
  className?: string;
}

const Background: React.FC<BackgroundProps> = ({
  testId, className = "background-container"
}) => {
  return <div className={className} data-testid={testId}></div>;
}

export default Background;
  