import React from "react";
import "./Disclaimer.scss"

interface DisclaimerProps{
  testId?: string;
}

export const Disclaimer: React.FC<DisclaimerProps> = ({testId}) => {
  return <p className="disclaimer" data-testid={testId}>By submitting the following form you agree to send your information to the team conducting this study who will contact you and have read and understood the Care Research Hub Privacy Policy.</p>;
}
  