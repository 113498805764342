import React, { ReactNode } from "react";
import "./QuestionLabel.scss"

interface QuestionLabelProps {
    testId?: string;
    children?: ReactNode;
    className?:string;
}

export const QuestionLabel: React.FC<QuestionLabelProps> = ({ testId, children, className }) => {

    return <div className={className} data-testid={testId}>{children}</div>
}