import React from 'react';
import './QuestionnairePage.scss';
import Logo from '../../commonComponents/logo/logo';
import QuestionnaireForm from './QuestionnaireForm/QuestionnaireForm';
import { Breadcrumb } from './Breadcrumb/Breadcrumb';
import Background from '../../commonComponents/background/Background';

const QuestionnairePage: React.FC = () => {
  return (
    <div className='questionnaire-page'>
      <Background testId='questionnaireBackground' className='background-container-questionnaire' />
      <div className="questionnaire-page-container">
        <Logo testId="logo-questionnaire" />
        <Breadcrumb testId="breadcrumb" steps={["Enter your details", "Measure vital signs"]} activeStep={0} />
        <QuestionnaireForm testId="questionnaireForm" />
      </div>
    </div>
  );
};

export default QuestionnairePage;
