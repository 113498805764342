import React from "react";
import "./TextInput.scss"
import { FormQuestion } from "../FormQuestion/FormQuestion";

interface TextInputProps {
    testId?: string;
    inputType: string;
    label: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    value?: string | number;
    placeholder?: string;
}

export const TextInput: React.FC<TextInputProps> = ({ testId, inputType, label, onChange, value, placeholder }) => {
    return <FormQuestion label={label} testId={testId}>
        <div className="field-container-box">
            <input
                type={inputType}
                value={value}
                onChange={onChange}
                className={`text-input ${inputType === 'number' ? 'numeric-field' : ''}`}
                placeholder={placeholder}
            />
        </div>
    </FormQuestion>
}
