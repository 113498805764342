import React from "react";
import "./Title.scss"

interface TitleProps{
  testId?: string;
}

const Title: React.FC<TitleProps> = ({testId}) => {
  return <h1 className="landing-page-title" data-testid={testId}>Clinical trial for Anxiety Treatment</h1>
}

export default Title;