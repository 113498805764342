import React, { ReactNode } from "react";
import { QuestionLabel } from "./QuestionLabel/QuestionLabel";
import "./FormQuestion.scss";

interface FormQuestionProps {
    testId?: string;
    label?: string;
    children?: ReactNode;
    type?: string;
    multiline?: boolean;
}

export const FormQuestion: React.FC<FormQuestionProps> = ({ testId, label, children, type, multiline }) => {
    const labelNode = () => { return label? <QuestionLabel className={multiline?"field-label-multiline":"field-label"}>{label}</QuestionLabel>: ""}

    return <div className="field-container" data-testid={testId}>
        {type === "text"?<div className="text-input-field">{labelNode()}</div>:labelNode()}     
        {children}
    </div>
}