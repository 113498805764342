import React, { ChangeEvent } from "react";

interface RadioInputItemProps {
    testId?: string;
    index: number;
    item: string;
    onChangeFunction: (event: React.ChangeEvent<HTMLInputElement>) => void;
    name: string;
    checked: boolean;
}

export const RadioInputItem: React.FC<RadioInputItemProps> = ({ testId, index, item, onChangeFunction, name, checked }) => {
    return <div className="radio-field radio-field-vertical">
        <label>
            <input type="radio" name={name} value={index.toString()} checked={checked} onChange={(event) => onChangeFunction(event)} />
            <span>{index.toString()} - {item}</span>
        </label>
    </div>
}